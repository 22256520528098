'use strict'
import axios from 'axios'
import router from '../router'
import {Notify} from 'vant';
import {storage} from '../unit/units'
// 默认超时时间
axios.defaults.timeout = 1000 *10

// axios.defaults.withCredentials = true
/**
 * 请求拦截器
 */

axios.interceptors.request.use(
    async (config) => {
        // 解析秘钥
        if (storage.get('USERSIGN')) {
            config.headers['USERSIGN'] = storage.get('USERSIGN')
        }
        config.headers['Blade-Auth'] = `bearer ${storage.get('token')}`
        config.headers['Authorization'] = 'Basic bWFnaWdlbmVfaDU6bWFnaWdlbmVfaDU='
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

/**
 * 响应拦截器
 */
axios.interceptors.response.use(
    (response) => {
        if ( response.data.code && response.data.code === 700) {
            Notify({
                type: 'danger',
                message: '秘钥过期请重新输入'
            })
            router.push({
                path: '/salary',
                query: {
                    redirect: location.href
                }
            })
        }
        return response.data
    },
    (error) => {
        Notify({
            type: 'danger',
            message: '当前登录已失效请重新登录'
        })
        router.push({
            path: '/login',
        })
        return Promise.reject(error)
    }
)


export default axios
